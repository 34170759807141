// Side effects
import './setBatchUpdatesFn';
import './setLogger';
export { QueryClientProvider, useQueryClient } from './QueryClientProvider';
export { QueryErrorResetBoundary, useQueryErrorResetBoundary } from './QueryErrorResetBoundary';
export { useIsFetching } from './useIsFetching';
export { useIsMutating } from './useIsMutating';
export { useMutation } from './useMutation';
export { useQuery } from './useQuery';
export { useQueries } from './useQueries';
export { useInfiniteQuery } from './useInfiniteQuery'; // Types

export * from './types';